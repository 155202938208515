import React from "react";
import ContactIcon4 from "../../Assets/Img/other-logos/contact-icon-4.png";
import Animate from "../Animation/Animate";


const ContactLocation = () => {
  return (
    <div className="container py-5">
      <div className="row text-center">
        <div className="title-location-card">
          <Animate>
          <h1>Office Locations</h1>
          </Animate>
          
          <p>United States of America</p>
        </div>
      </div>
      <div className="row mt-5">
        
        <div className="mb-4 col-lg-4">
           <div className="cardLocationBody">
             <img className="locationImg" src={ContactIcon4} alt=".." />
             <div className="cardLocation-content">
               <h2>Virginia</h2>
               <p>
               4140 Parker Rd. Allentown,<br /> New Mexico 31134
               </p>
             </div>
           </div>
        </div>
        <div className="mb-4 col-lg-4">
           <div className="cardLocationBody">
             <img className="locationImg" src={ContactIcon4} alt=".." />
             <div className="cardLocation-content">
               <h2>Massachusetts</h2>
               <p>
               82 Wendell Ave. Suite <br /> 100 Pittsfield MA 01201
               </p>
             </div>
           </div>
        </div>
        <div className="mb-4 col-lg-4">
           <div className="cardLocationBody">
             <img className="locationImg" src={ContactIcon4} alt=".." />
             <div className="cardLocation-content">
               <h2>lIllinois</h2>
               <p>
               2501 Chatham Rd Ste R <br /> Springfield IL 62704
               </p>
             </div>
           </div>
        </div>
        <div className="mb-4 col-lg-4">
           <div className="cardLocationBody">
             <img className="locationImg" src={ContactIcon4} alt=".." />
             <div className="cardLocation-content">
               <h2>Maryland</h2>
               <p>
               5000 Thayer Center Suite <br /> C Oakland MD 21550
               </p>
             </div>
           </div>
        </div>
        <div className="mb-4 col-lg-4">
           <div className="cardLocationBody">
             <img className="locationImg" src={ContactIcon4} alt=".." />
             <div className="cardLocation-content">
               <h2>New York</h2>
               <p>
               2501 Chatham Rd Ste R <br /> Springfield IL 62704
               </p>
             </div>
           </div>
        </div>
        <div className="mb-4 col-lg-4">
           <div className="cardLocationBody">
             <img className="locationImg" src={ContactIcon4} alt=".." />
             <div className="cardLocation-content">
               <h2>Rhode Island</h2>
               <p>
               47 Wood Ave Suite <br /> 2 Barrington RI 02806
               </p>
             </div>
           </div>
        </div>
      </div>
      <div className="map">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3099.738051114962!2d-77.48026352424466!3d39.02128857169784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63ed73f1b4975%3A0x41b21c46efa2f77a!2s44031%20Pipeline%20Plaza%20STE%20300%2C%20Ashburn%2C%20VA%2020147%2C%20USA!5e0!3m2!1sen!2sin!4v1710932976289!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="embed-responsive-item google-map"
          style={{ width: "100%" }}
        ></iframe>
      </div>
    </div>
  );
};


export default ContactLocation;