
import { v4 as uuidv4 } from "uuid";
import Instance from "./AxiosConfig";
import Swal from "sweetalert2";


export const REACT_APP_FRONTEND_URL_WEBSITE = "https://bilkins.com/";
export const REACT_APP_FRONTEND_URL_CMS = "https://cms.bilkins.com/";

export const handleFileUpload = async (file, fileName, type) => {
    try {
      const response = await Instance.get('/getUploadUrl', {
        params: {
          fileName: fileName,
          contentType: type,
        },
      });
      const presignedUrl = response?.data?.url;

      await Instance.put(presignedUrl, file, {
        headers: {
          'Content-Type': type,
        },
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  export const createUniqueS3FilenameWithDate = (filename) => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    const uniqueId = uuidv4();
    const ext = filename.split('.').pop();
    const baseName = filename.replace(`.${ext}`, '');
    const validBaseName = baseName.replace(/[^a-zA-Z0-9-_]/g, '_');
    const uniqueFilename = `${validBaseName}-${date}-${uniqueId}.${ext}`;
    return uniqueFilename;
  }

  export const showErrorAlert = (message) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Error",
      text: message,
      customClass: {
        icon: "centered-icon",
      },
    });
  };
  export const showSuccessAlert = (message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Successful",
      text: message,
      customClass: {
        icon: "centered-icon",
      },
    });
  };

  export const formateDate = (date) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  };
