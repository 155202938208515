import React from 'react'
import BannerSickLeave from '../../Components/BenefitsPay/Holiday/BannerSickLeave';
import ContentSickLeave from '../../Components/BenefitsPay/Holiday/ContentSickLeave';

const Holiday = () => {
  return (
    <>
    <BannerSickLeave />
    <ContentSickLeave />
    </>
  )
}

export default Holiday;