import React, { useEffect, useState } from 'react';
import JobDetailComp from '../../Components/JobComponents/JobDetailComp';
import WhyChooseUs from '../../Components/Home/WhyChooseUs';
import JobSuits from '../../Components/Home/JobSuits';
import Instance from '../../AxiosConfig';
import { useParams } from 'react-router-dom';

const JobDetail = () => {
  const { jobId } = useParams();
  const [jobData, setJobData] = useState(null);

  const fetchJobDetail = async () => {
    try {
      const response = await Instance.get(`/getJobById/${jobId}`);
      if (response.status === 200) {
        setJobData(response?.data?.job);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchJobDetail();
  }, [jobId]);


  return (
    <div>
      <JobDetailComp jobData={jobData} />
      <WhyChooseUs />
      <JobSuits />
      <div className='div-margin'></div>
    </div>
  )
}

export default JobDetail;