import React from 'react'
import BannerSickLeave from '../../Components/BenefitsPay/Education/BannerSickLeave';
import ContentSickLeave from '../../Components/BenefitsPay/Education/ContentSickLeave';

const Education = () => {
  return (
    <>
    <BannerSickLeave />
    <ContentSickLeave />
    </>
  )
}

export default Education;