import React from 'react'
import BannerSickLeave from '../../Components/BenefitsPay/SickLeave/BannerSickLeave'
import ContentSickLeave from '../../Components/BenefitsPay/SickLeave/ContentSickLeave'

const SickLeave = () => {
  return (
    <>
    <BannerSickLeave />
    <ContentSickLeave />
    </>
  )
}

export default SickLeave;