import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import About from "./Pages/About/About";
import Service from "./Pages/Service/Service";
import Career from "./Pages/Career/Career";
import ContactPage from "./Pages/Contact/contactForm.jsx";
import Hiringlocation from "./Pages/HiringLocation/hiringLocation";
import ScrollToTop from "./Components/ScrollTop/ScrollTop";
import { GridLoader } from "react-spinners";
import { HealthCare } from "./Pages/HealthCareService/HealthCare";
import { FullApplication } from "./Pages/ApplyNow/FullApplication.jsx";
import { QuickApplication } from "./Pages/ApplyNow/QuickApplication.jsx";
import { JoinUsQuickApplication } from "./Pages/Join Us/JoinUsQuickApplication.jsx";
import { JoinUsFullApplication } from "./Pages/Join Us/JoinUsFullApplication.jsx";
import SignUp from "./Pages/Auth/signup.jsx";
import { SignIn } from "./Pages/Auth/signin.jsx";
import PostDetail  from "./Pages/PostDetails/PostDetail.jsx";
import { JobPages } from "./Pages/JobPages/JobPage.jsx";
import JobDetail from "./Pages/JobPages/JobDetail";
import Error404 from "./Components/404Error/Error404.jsx";
import Refer from "./Pages/Refer/Refer.jsx";
import ForgotPassword from "./Pages/Auth/ForgotPassword.jsx";
import ResetPassword from "./Pages/Auth/ResetPassword.jsx";
import WeeklyPay from "./Pages/BenefitsPay/WeeklyPay.jsx";
import SickLeave from "./Pages/BenefitsPay/SickLeave.jsx";
import Holiday from "./Pages/BenefitsPay/Holiday.jsx";
import ShortTerm from "./Pages/BenefitsPay/ShortTerm.jsx";
import Travel from "./Pages/BenefitsPay/Travel.jsx";
import Referal from "./Pages/BenefitsPay/Referal.jsx";
import Housing from "./Pages/BenefitsPay/Housing.jsx";
import Education from "./Pages/BenefitsPay/Education.jsx";
import Employees from "./Pages/BenefitsPay/Employees.jsx";

const Layout = ({ children }) => {
  const location = useLocation();
  const shouldRenderFooter =
    !location.pathname.includes("/forgot-password") &&
    !location.pathname.includes("/reset-password") &&
    !location.pathname.includes("/search-jobs");
  const shouldRenderHeader =
    !location.pathname.includes("/forgot-password") &&
    !location.pathname.includes("/reset-password");

  return (
    <>
      {shouldRenderHeader && <Header />}
      {children}
      {shouldRenderFooter && <Footer />}
    </>
  );
};

const AppRoutes = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const hideLoader = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(hideLoader);
  }, []);

  return (
    <Router>
      <Layout>
        <ScrollToTop />
        {isLoading && (
          <div className="loader">
            <GridLoader size={15} color={"#123abc"} loading={isLoading} />
          </div>
        )}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/sign-up" element={<SignUp />} />
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/weekly-pay" element={<WeeklyPay />} />
          <Route exact path="/sick-leave" element={<SickLeave />} />
          <Route exact path="/holiday" element={<Holiday />} />
          <Route exact path="/short-term" element={<ShortTerm />} />
          <Route exact path="/travel" element={<Travel />} />
          <Route exact path="/referal-bonus" element={<Referal />} />
          <Route exact path="/housing" element={<Housing />} />
          <Route exact path="/education" element={<Education />} />
          <Route exact path="/employees" element={<Employees />} />
          <Route exact path="/service" element={<Service setIsLoading={setIsLoading} />} />
          <Route exact path="/search-jobs" element={<JobPages />} />
          <Route exact path="/join-us/quick-application" element={<JoinUsQuickApplication />} />
          <Route exact path="/join-us/full-application" element={<JoinUsFullApplication />} />
          <Route path="/apply-now/quick-application/:id" element={<QuickApplication />} />
          <Route path="/apply-now/full-application/:id" element={<FullApplication />} />
          <Route exact path="/job-detail/:jobId" element={<JobDetail />} />
          {/* <Route
            exact
            path="/post/:categorySlug/:postId"
            element={<PostDetail setIsLoading={setIsLoading} />}
          /> */}
          <Route exact path="/location" element={<Hiringlocation />} />
          {/* <Route path="/health-care" element={<HealthCare />} /> */}
          <Route path="/:postUrl" element={<PostDetail />} />
          <Route path="/job-pages" element={<JobPages />} />
          <Route path="/refer" element={<Refer />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/*" element={<Error404 />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRoutes;
