import React from 'react'
import BannerWeekly from '../../Components/BenefitsPay/WeeklyPay/BannerWeekly'
import ContentWeekly from '../../Components/BenefitsPay/WeeklyPay/ContentWeekly'

const WeeklyPay = () => {
  return (
    <>
    <BannerWeekly />
    <ContentWeekly />
    </>
  )
}

export default WeeklyPay