import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MultiStepForm from '../../Components/MultiStepForm/LinearStepper';
import Instance from '../../AxiosConfig';
import { FaArrowLeft } from "react-icons/fa6";

export const FullApplication = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [jobData, setJobData] = useState(null);

    const fetchJobDetail = async () => {
        try {
            const response = await Instance.get(`/getJobById/${id}`);
            if (response.status === 200) {
                setJobData(response?.data?.job);
            } else {
                navigate(-1);
            }
        } catch (error) {
            console.error(error);
            navigate(-1);
        }
    }

    useEffect(() => {
        async function fetchData() {
            await fetchJobDetail();

        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!id) navigate(-1);
    }, [])

    const handlequickLink = () => {
        navigate(`/apply-now/quick-application/${id}`);
    }
    const handlefullLink = () => {
        navigate(`/apply-now/full-application/${id}`);
    }
    return (
        <div>
            <>
                <Container className='apply-job-now'>
                    <h1 className='apply-job-main-head'>{jobData?.title}</h1>
                    <Row>
                        <Col md={12} className='apply-job-left'>
                            <button onClick={() => navigate(-1)} className='back-button'><FaArrowLeft />Back</button>
                            <div className="d-flex gap-4 justify-content-center ms-4">
                                <button onClick={handlequickLink} className='btn-log-in'>Quick Appliction</button>

                                <button onClick={handlefullLink} className='btn-sign-up'>Full Application</button>
                            </div>
                        </Col>
                        <Col md={12} className="apply-job-right-col">
                            <MultiStepForm jobData={jobData} />
                        </Col>
                    </Row>
                </Container>
            </>
        </div>
    )
}
