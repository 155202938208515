import React from 'react';
import BannerSickLeave from '../../Components/BenefitsPay/Housing/BannerSickLeave';
import ContentSickLeave from '../../Components/BenefitsPay/Housing/ContentSickLeave';


const Housing = () => {
  return (
    <>
    <BannerSickLeave />
    <ContentSickLeave />
    </>
  )
}

export default Housing;