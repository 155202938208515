import React from "react";
import Referal from "../../Components/Refer/Referal";

const Refer=()=>{
    return(
        <>
        <Referal/>
        </>
    )
}
export default Refer;