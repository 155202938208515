import React from 'react'
import ContentSickLeave from '../../Components/BenefitsPay/Travel/ContentSickLeave';
import BannerSickLeave from '../../Components/BenefitsPay/Travel/BannerSickLeave';

const Travel = () => {
  return (
    <>
    <BannerSickLeave />
    <ContentSickLeave />
    </>
  )
}

export default Travel;